import React from "react";
import { Conversation } from "../../components/Conversation";

export const ConversationalScreen = ({
  data,
  setValueSelector,
  userInputs,
  states,
  isNumberSubmitted,
  setIsNumberSubmitted,
  toggleStateDropdown,
  scrollToBottom,
  isDesktopView,
  isOtpSubmitted,
  handleResendOtp
}) => {
  return data.map((item, index) => {
    return (
      <Conversation
        isDesktopView={isDesktopView}
        scrollToBottom={scrollToBottom}
        isNumberSubmitted={isNumberSubmitted}
        setIsNumberSubmitted={setIsNumberSubmitted}
        key={index}
        id={item.id}
        question={item.question?.msg}
        type={item.type}
        options={item.answer ? item.answer : item?.question?.options}
        setValue={(id, value, next, nextConversationId, type, key) =>
          setValueSelector(id, value, next, nextConversationId, type, key)
        }
        msg={item.msg}
        next={item.next}
        value={userInputs[item.id] || ""}
        address={item.address}
        imageName={item.name}
        states={states}
        subtype={item.subtype}
        isSubmitted={item?.isSubmitted}
        toggleStateDropdown={toggleStateDropdown}
        isOtpSubmitted={isOtpSubmitted}
        handleResendOtp={handleResendOtp}
      />
    );
  });
};
