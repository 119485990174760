import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import "./homeScreen.css";
import { conversationsENG, conversationsKA, conversationsMA } from "../../data/contents";
import { conversationsTEL } from "../../data/contents";
import { conversationsTA } from "../../data/contents";
import axios from "axios";
import Headings from "../../components/Headings/Headings";
import { ConversationalScreen } from "../ConversationalScreen/ConversationalScreen";
import { AndroidScreen } from "../../components/AndroidScreen";
import SouravDadaImage from "../../components/SouravDadaImage/SouravDadaImage";
import RadarScreen from "../RadarScreen/RadarScreen";
import { DropdownLowerPart } from "../../components/DropdownLowerPart";
import { getDisplayName } from "../../utils";
import { baseUrl } from "../../utils/api";
import name from "../../assets/Images/recommends.png";
import i18n from "../../i18n";
import {fetchGrowthAndWhatsapp} from "../../utils/index";
import trackEvent from "../../components/utils";
import { getConfig, getItemWithExpiry, getMessage } from "../../utils/helper";
import Toast from "../../components/Toast/Toast";



// import upArrow from "../../assets/Images/upArrow.svg";
// import Draggable from "react-draggable";

function addOtpStepOnConversation(conversations){
  conversations["coaching-number"].next = "coaching-number-otp-intro"
  conversations["youtuber-number"].next = "youtuber-number-otp-intro"
  conversations["teacher-number"].next = "teacher-number-otp-intro"
  conversations["student-teacher-number"].next = "student-teacher-number-otp-intro"
}
const HomeScreen=({
  uids,
  communityUrls,
  language,
  desktopView,
  setNumberSubmitted = () => {},
  setInstituteName = () => {},
  hideDadaImage,
})=> {
  const [mobileLan, setMobileLan ] = useState(desktopView ? language :language)
  const [userInputs, setUserInputs] = useState({});
  const [uid, setUid] = useState(uids);
  const [mobileUid, setMobileUid]=useState()
  const [conversations,setConversations]=useState(language === "EN"?conversationsENG : conversationsENG)
  const [mobilecommunityUrl, setMobileCommunityUrl]=useState('https://clps.app/eNUp')
  const [isOptVeficationEnabled, setIsOptVeficationEnabled] = useState(false) 
  const [sessionId, setSessionId] = useState(null);
  const [toastDetails, setToastDetails] = useState({ message: "", type: "", show: false });

  useEffect(() => {
    if (typeof localStorage !== "undefined") {
      const otpEnabled = getItemWithExpiry('otpEnabled');
      const sessionId = getItemWithExpiry('session');
      if (otpEnabled === null || otpEnabled === undefined) {
        return checkOtpEnabled()
      }
      setSessionId(sessionId);
      if (otpEnabled === 1) {
        setIsOptVeficationEnabled(Boolean(otpEnabled));
      }else{
        setIsOptVeficationEnabled(false);
      }
    }
  }, []);

  async function checkOtpEnabled() {
    try {
      const {session, isOtpEnabled} = await getConfig();
      setIsOptVeficationEnabled(Boolean(isOtpEnabled))
      setSessionId(session);
    } catch (error) {
      console.error("error while fetching config")
    }
  }
  
  useEffect(()=>{
    if(language =="TA")
    {
      setConversations(conversationsTA)
    }
    if(language == "EN")
    {
      setConversations(conversationsENG)
    }
    if(language == "TE")
    {
      setConversations(conversationsTEL)
    }
    if(language == "MA")
    {
      setConversations(conversationsMA)
    }
    if(language == "KA")
    {
      setConversations(conversationsKA)
    }

  },[language, isOptVeficationEnabled])

  const [data, setData] = useState([conversations?.["name"]]);

  const [states, setStates] = useState([]);
  const [isNumberSubmitted, setIsNumberSubmitted] = useState(false);
  const [isOtpSubmitted, setIsOtpSubmitted] = useState(false);
  const [resendOtpCount, setResendOtpCount] = useState(0);
  const [showRadarScreen, setShowRadarScreen] = useState(false);
  const [showStateDropdown, setShowStateDropdown] = useState(false);
  // const [communityUrl, setCommunityUrl]=useState('https://clsps.link/iWBcv0')
  const [communityUrl, setCommunityUrl]=useState('https://clsps.link/NXw8ge')
  // const [position, setPosition] = useState({
  //   x: 0,
  //   y: 0,
  // });
  useEffect(()=>{
    if(desktopView)
    {
      setMobileLan(desktopView ? language : mobileLan)
    }
    // i18n.changeLanguage(mobileLan);
    if(mobileLan =="TA")
    {
      setConversations(conversationsTA)
    }
    if(mobileLan == "EN")
    {
      setConversations(conversationsENG)
    }
    if(mobileLan == "TE")
    {
      setConversations(conversationsTEL)
    }
    if(mobileLan == "MA")
    {
      setConversations(conversationsMA)
    }
    if(mobileLan == "KA")
    {
      setConversations(conversationsKA)
    }
   setData([conversations["name"]])
  },[language,conversations,mobileLan, isOptVeficationEnabled])

  useEffect(() => {
    if(isOptVeficationEnabled){
      addOtpStepOnConversation(conversations)
    }
  }, [conversations, isOptVeficationEnabled, language, language]);
  const navigate = useNavigate();

  const getUUID = async (
    utm_campaign,
    utm_medium,
    utm_source,
    utm_content,
    utm_term,
    gclID
  ) => {
    try {
      const fbp = document.cookie
        .split(";")
        .filter((c) => c.includes("_fbp="))
        .map((c) => c.split("_fbp=")[1]);
      const fbc = document.cookie
        .split(";")
        .filter((c) => c.includes("_fbc="))
        .map((c) => c.split("_fbc=")[1]);

      let fbpCookie = fbp && fbp[0];
      let fbcCookie = fbc && fbc[0];

      const { data } = await axios.post(`${baseUrl}/growth/saveLead`, {
        utm_campaign,
        utm_medium,
        utm_source,
        utm_content,
        utm_term,
        gclId: gclID,
        fbp: fbpCookie,
        fbc: fbcCookie,
        leadIntent:"clp-old",
      });
      setUid(data.data.uuid);
      setCommunityUrl(data?.data?.languageDetails?.communityUrl)

    } catch (err) {
      console.log(err);
    }
  };

  const getCityAndStates = async () => {
    try {
      const {
        data: {
          data: { states },
        },
      } = await axios.get(`${baseUrl}/su/affiliate/cityAndStates`);
      const statesWithNext = states.map((item) => {
        return {
          id: "state",
          value: item,
          key: item,
        };
      });
      setStates(statesWithNext);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    let utm_source = url.searchParams.get("utm_source");
    let utm_campaign = url.searchParams.get("utm_campaign");
    let utm_medium = url.searchParams.get("utm_medium");
    let utm_content = url.searchParams.get("utm_content");
    let utm_term = url.searchParams.get("utm_term");
    let gclID = url.searchParams.get("gclid");
    // getUUID(utm_campaign, utm_medium, utm_source, utm_content, utm_term, gclID);
    getCityAndStates();
  }, []);


  

  const updateConversation = async (isGoToNext, payload, type) => {
    if (Object.keys(payload).length && isGoToNext) {
      try {
        if (type === "multiSelect") {
          const key = Object.keys(payload)[0];
          payload[key] = payload[key].join(",");
        }
        await axios.patch(`${baseUrl}/growth/saveDetails`, {
          ...payload,
          uuid: desktopView ? uids?.uuid : mobileUid?.uuid,
          language,
          leadIntent: "clp-old",
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const updateData = (oldData) => {
    const valueArr = oldData.map((item) => item.id);
    const updatedData = [];
    valueArr.forEach(function (item, idx) {
      if (valueArr.indexOf(item) === idx) updatedData.push(item);
    });
    const finalData = updatedData.map((data) => conversations[data]);
    return finalData;
  };

  const getNextData = (oldData, nextData, extraDelay) => {
    const delay = 1000 + (extraDelay === 0 ? -1000 : extraDelay);
    if (nextData) {
      setTimeout(() => {
        setData(updateData([...oldData, nextData]));
      }, 1000);
    } else {
      setTimeout(() => {
        setData((prev) => {
          return updateData([...prev, ...oldData]);
        });
      }, delay);
    }
  };

  const getSimpleMsgs = (conversationsData, nextConvoId) => {
    let currentObj = conversationsData[nextConvoId];
    for (let index = 0; currentObj && currentObj.type === "simple"; index++) {
      getNextData([currentObj], "", index * 1000);
      if (!currentObj.isEnd) {
        currentObj = conversationsData[currentObj.next];
        if (currentObj?.type !== "simple") {
          getNextData([currentObj], "", (index + 1) * 1000);
        }
      } else {
        break;
      }
    }
  };

  const scrollToBottom = () => {
    const bottomDivMobile = document.querySelector(".conversationContainer");
    const bottomDivDesktop = document.querySelector(
      ".conversationContainer__desktop"
    );

    const bottomDiv = bottomDivMobile || bottomDivDesktop;
    if (bottomDiv) {
      bottomDiv.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    } else {
      const joinFamilyButton = document.querySelector(".joinFamilyButton");
      if (joinFamilyButton) {
        joinFamilyButton.scrollIntoView(false);
      }
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [isNumberSubmitted, data]);

  useEffect(() => {
    if (isNumberSubmitted) {
      setInstituteName(
        getDisplayName(
          userInputs["coaching-name"] ||
            userInputs["youtuber-name"] ||
            userInputs["teacher-name"] ||
            userInputs["online-course-name"]
        )
      );
      setNumberSubmitted(true);
    }
  }, [userInputs, setInstituteName, isNumberSubmitted, setNumberSubmitted]);

  const trackLeadNow = (uuid) => {
    window.gtag("event", "submit", {
      event_category: "convo_form",
      event_label: "phone_number",
    });
    window.fbq("track", "Lead", {}, { eventID: uuid });
  };
  const generateOtp = async () => {
    const phoneNumber = userInputs["coaching-number"] ||
              userInputs["youtuber-number"] ||
              userInputs["teacher-number"] ||
              userInputs["student-teacher-number"];
    const countryExt = getItemWithExpiry('countryExt', false) || '91';

    if (!phoneNumber) {
      console.error("Phone number is required to generate OTP");
      return;
    }

    const body = {
      phoneNumber,
      countryExt,
      sessionId,
      otpCount: resendOtpCount,
      language,
      leadIntent: "clp-old",
    };

    try {
      await axios.post(`${baseUrl}/growth/otp/generate`, body);
      setToastDetails({ message: getMessage(mobileLan || language, "otpSent"), type: "success", show: true });
      console.log("OTP Generated successfully");
      setResendOtpCount(resendOtpCount + 1);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setToastDetails({ message: error.response?.data?.message || "Something went wrong, Please Retry", type: "error", show: true });
        console.error("Error generating OTP:", error);
        return
      } 
      setToastDetails({ message: getMessage(mobileLan || language, "errorGeneratingOtp"), type: "error", show: true });
      console.error("Error generating OTP:", error);
    }
  };
  const setValueSelector = async (
    id,
    value,
    goToNext = true,
    nextConversationId,
    type,
    key
  ) => {
    if(conversations[id].key === "otp" && goToNext){
        const response = await verifyOtp(value);
        if(!response){
          return
        }
      }

    setUserInputs({ ...userInputs, [id]: value });

    if (conversations[id].key && id !== 'learnGrowthTips') {
      updateConversation(
        goToNext,
        { 
          [conversations[id].key]: type === "radio" ? key : value,
          ...((conversations[id].key === "otp" && goToNext) && {isOtpVerified: 1})
         },
        type
      );
      if(conversations[id].key === "phoneNumber" && goToNext && isOptVeficationEnabled){
        generateOtp();
      }
    }

    if (conversations[id].next === "end") {
      if (id === "learnGrowthTips") {
        let uid= desktopView ? uids : mobileUid;
        let communityUrl= desktopView ? communityUrls : mobilecommunityUrl;
        let payload = {
          uuid: uid?.uuid,
          [conversations[id].key]: type === "radio" ? key : value,
          language
        }
        if(key === 1) {
          /*
            save growth and open whatsapp
          */
          payload['growthTipsSource'] = 'Homepage/yes';
          payload['submitted'] = true;
          fetchGrowthAndWhatsapp(communityUrl, payload, true);
       } else {
          /*
            We have sourav ganguly image on home screen
            which is displayed even when radar(now bindra)
            page is shown.
            We want to hide this image if bindra screen is opened
            passing this function and hiding on bindra image render
            checking this if it exists because in mweb we dont have
            sourav image.
          */
          if(typeof hideDadaImage === "function") {
            hideDadaImage();
          }
            /*
            save growth and dont open whatsapp
            */
            payload['growthTipsSource'] = 'Homepage/no';
          fetchGrowthAndWhatsapp(communityUrl, payload, false);
          setShowRadarScreen(true);
        }
      } else setShowRadarScreen(true);
    }

    if (goToNext) {
      trackEvent(id, "conversation", value);
      if (conversations[id].switchScreen) {
        setIsNumberSubmitted(true);
      }
      if (conversations[id].next === "thanks") {
        navigate("/ThankYou");
      }
      if (conversations[id].key === "phoneNumber") {
        trackLeadNow(uid);
      }

      // const newData = [...data, conversations[nextConversationId]];

      const answerUpdater = (conversations, id, value) => {
        let simpleAns = {};
        if (type === "multiSelect") {
          simpleAns = value.map((item) => {
            return {
              key: item,
              value: item,
              next: nextConversationId,
            };
          });
          conversations[id].answer = [...simpleAns];
        } else {
          simpleAns = {
            key: value,
            value,
            next: nextConversationId,
          };
          conversations[id].answer = [simpleAns];
        }
      };

      answerUpdater(conversations, id, value);

      if (
        conversations[nextConversationId] &&
        conversations[nextConversationId].type === "simple"
      ) {
        getSimpleMsgs(conversations, nextConversationId);
      } else if (conversations[nextConversationId]) {
        setData((prev) => {
          return updateData([...prev, conversations[nextConversationId]]);
        });
      }
    }
  };

  const toggleStateDropdown = () => {
    setShowStateDropdown(!showStateDropdown);
  };

  const selectDropdownValue = (id, value, next) => {
    setValueSelector(id, value, true, next);
    toggleStateDropdown();
  };

  const verifyOtp = async (otp) => {
    const phoneNumber = userInputs["coaching-number"] ||
                        userInputs["youtuber-number"] ||
                        userInputs["teacher-number"] ||
                        userInputs["student-teacher-number"];
    const countryExt = getItemWithExpiry('countryExt', false) || '91';

    if (!phoneNumber || !otp) {
      console.error("Phone number and OTP are required for verification");
      return false;
    }

    const body = {
      phoneNumber,
      countryExt,
      sessionId,
      otp,
      language,
      leadIntent: "clp-old",
    };

    try {
      const response = await axios.post(`${baseUrl}/growth/otp/verify`, body);
      if (response.status === 200) {
        console.log("OTP verified successfully");
        setToastDetails({ message: getMessage(mobileLan || language, "otpVerified"), type: "success" , show: true});
        setIsOtpSubmitted(true)
        return true;
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setToastDetails({ message: getMessage(mobileLan || language, "invalidOtp"), type: "error", show: true });
        console.error("OTP verification failed: Unauthorized");
        return false
      } 
      if (error.response && error.response.status === 403) {
        setToastDetails({ message: error.response?.data?.message || "Something went wrong, Please Retry", type: "error", show: true });
        console.error("Error verifying OTP:", error);
        return
      } 
      setToastDetails({ message: getMessage(mobileLan || language, "somethingWentWrong"), type: "error", show: true });
      console.error("Error verifying OTP:", error);
    }
    return false;
  };


  return (
    <div className={`App p-4 column flex flex-col`}>
      {desktopView ? (
        ""
      ) : (
        <Headings
        language={mobileLan}
        setMobileLan={setMobileLan}
          showRadarScreen={showRadarScreen}
          userInputs={userInputs}
          isNumberSubmitted={isNumberSubmitted}
          mobileLan={mobileLan}
          setMobileCommunityUrl={setMobileCommunityUrl}
          setMobileUid={setMobileUid}
          coachingName={getDisplayName(
            userInputs["coaching-name"] ||
              userInputs["youtuber-name"] ||
              userInputs["teacher-name"] ||
              userInputs["online-course-name"]
          )}
        />
      )}
      {showRadarScreen ? (
        <RadarScreen
          desktopView={desktopView}
          userInputs={userInputs}
          uid={ desktopView ? uids : mobileUid}
          communityUrl={desktopView ? communityUrls : mobilecommunityUrl}
        />
      ) : (
        <>
          <div className="m-3">
            {!desktopView && (
              <SouravDadaImage
                dadaFadeOut={!userInputs["name"] ? false : true}
              />
            )}
            {showStateDropdown ? (
              <DropdownLowerPart
                dropdownOptions={states}
                selectDropdownValue={selectDropdownValue}
                toggleStateDropdown={toggleStateDropdown}
              />
            ) : null}

            {isNumberSubmitted && !desktopView ? (
              <AndroidScreen userInputs={userInputs} />
            ) : (
              ""
            )}
            {desktopView ? null : (
              <div
                className={`recommendsImg mb-6 ${
                  userInputs["name"] ? "hideInfo" : ""
                }`}
              >
              </div>
            )}

            {desktopView ? (
              <div className="conversationContainer__desktop mt-20">
                <ConversationalScreen
                  data={data}
                  userInputs={userInputs}
                  states={states}
                  setValueSelector={setValueSelector}
                  isNumberSubmitted={isNumberSubmitted}
                  isOtpSubmitted={isOtpSubmitted}
                  setIsNumberSubmitted={setIsNumberSubmitted}
                  toggleStateDropdown={toggleStateDropdown}
                  scrollToBottom={scrollToBottom}
                  isDesktopView={desktopView}
                  handleResendOtp={generateOtp}
                />
              </div>
            ) : (
              <>
                {/* {isNumberSubmitted ? (
                  <Draggable
                    defaultPosition={position}
                    bounds=".m-3"
                    axis="y"
                    position={position}
                    onDrag={handleSlider}
                    onStop={handleStop}
                    onStart={handleStart}
                  >
                    <div className="fixed bottom-72 z-10  upArrowParent">
                      <img src={upArrow} alt="upArrow" className="upArrow" />
                    </div>
                  </Draggable>
                ) : (
                  ""
                )} */}
                <div
                  className={` ${
                    isNumberSubmitted
                      ? "conversationContainerParent fixed max-h-72 bottom-0 overflow-y-auto "
                      : " -m-6 px-4 py-1 "
                  }`}
                >
                  <div
                    className={`conversationContainer ${
                      userInputs["name"] ? "convo-slide" : ""
                    }
                    ${
                      isNumberSubmitted
                        ? "conversationContainerWithScreenshots"
                        : ""
                    }`}
                  >
                    <ConversationalScreen
                      data={data}
                      userInputs={userInputs}
                      states={states}
                      setValueSelector={setValueSelector}
                      isNumberSubmitted={isNumberSubmitted}
                      isOtpSubmitted={isOtpSubmitted}
                      setIsNumberSubmitted={setIsNumberSubmitted}
                      toggleStateDropdown={toggleStateDropdown}
                      scrollToBottom={scrollToBottom}
                      isDesktopView={desktopView}
                      handleResendOtp={generateOtp}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
      <div className={`h-1 hidden`} id="autoScroll"></div>
      {toastDetails.show ? <Toast message={toastDetails.message} type={toastDetails.type} onClose={setToastDetails}/> : null}
    </div>
  );
}

export default HomeScreen;
