import { MESSAGES } from "../data/contents";
import axios from "axios";
import { baseUrl } from "./api";

export function setItemWithExpiry(key, value, ttl) {
    const now = new Date();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
}

export function getItemWithExpiry(key, shouldRemove = true) {
    const itemStr = localStorage.getItem(key);

    // if the item doesn't exist, return null
    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry && shouldRemove) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key);
        return null;
    }
    return item.value;
}
export function generateSessionId() {
    return 'xxxx-xxxx-4xxx-yxxx-xxxx-xxxx-xxxx-xxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0,
              v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function getMessage(language, key) {
    if(MESSAGES?.[language]?.[key]) return MESSAGES[language][key]
    return MESSAGES['EN']?.[key] || ""
}

export async function getConfig() {
    try {
      const session = getItemWithExpiry("session");
      const isOtpEnabled = getItemWithExpiry("otpEnabled");
      if (session && (isOtpEnabled === 1 || isOtpEnabled === 0)) {
        return {session, isOtpEnabled}
      }
      const response = await axios.get(`${baseUrl}/growth/otp/config`);
      let configDetails = response && response.data && response.data.data;
      const sessionId = generateSessionId()
      if(configDetails?.otpConfig?.isOtpFlowEnabled){
        let showOtp = (configDetails.cpWebvisitorCount % configDetails?.otpConfig?.otpTriggerInterval === 0 )? 1 : 0
        setItemWithExpiry("session", sessionId, 86400000); // 24 hour TTL
        setItemWithExpiry("otpEnabled", showOtp, 86400000); // 24 hour TTL
        return {session: sessionId, isOtpEnabled: showOtp}
      }
      setItemWithExpiry("session", sessionId, 86400000); // 24 hour TTL
      setItemWithExpiry("otpEnabled", 0, 86400000); // 24 hour TTL
      return {session: sessionId, isOtpEnabled: 0}
    } catch (error) {
      console.error("Failed to fetch config:", error);
    }
  }