import React, { useState, useEffect } from 'react';
import './Toast.css'; // Create a CSS file for styling

const Toast = ({ message, type, onClose=()=> {} }) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
            onClose({message: '', type: '', show: false});
        }, 3000); // Auto close after 3 seconds

        return () => clearTimeout(timer);
    }, []);

    if (!visible) return null;

    return (
        <div className={`toast ${type}`}>
            {message}
        </div>
    );
};

export default Toast;