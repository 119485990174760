import React, { createContext, useState, useEffect } from "react";

import { ChatLoader } from "./ChatLoader";
import { Option } from "./Option";
import { Question } from "./Question";
import { Button } from "./Button";

export const ConversationContext = createContext(undefined);

export const Conversation = ({
  id,
  question,
  type,
  options,
  setValue,
  msg,
  next,
  value,
  address,
  imageName,
  states,
  subtype,
  setIsNumberSubmitted,
  isNumberSubmitted,
  switchScreen,
  toggleStateDropdown,
  scrollToBottom,
  isDesktopView,
  isOtpSubmitted,
  handleResendOtp
}) => {
  const [isLoading, setIsLoading] = useState(true);

  setTimeout(() => {
    setIsLoading(false);
  }, 2000);

  useEffect(() => {
    if (scrollToBottom) {
      scrollToBottom();
    }
  }, [isLoading, scrollToBottom]);

  const [timer, setTimer] = useState(60);

  useEffect(() => {
    if (timer > 0) {
      const countdown = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(countdown);
    }
  }, [timer]);

  function handleButtonClick(){
    try {
      setTimer(60)
      handleResendOtp()
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }
  return (
    <ConversationContext.Provider
      value={{
        subtype,
        isDesktopView,
        scrollToBottom,
        isNumberSubmitted: isNumberSubmitted,
        id: id,
        question: question,
        type: type,
        options: options,
        setValue: setValue,
        msg: msg,
        next: next,
        value: value,
        address: address,
        imageName: imageName,
        states: states,
        setIsNumberSubmitted: setIsNumberSubmitted,
        switchScreen: switchScreen,
        isOtpSubmitted: isOtpSubmitted
      }}
    >
      <div
        className={` ${
          id === "name"
            ? "conversationFirst"
            : type === "simple"
            ? "conversation-simple"
            : "conversation"
        }`}
      >
        {isLoading && question ? (
          <ChatLoader />
        ) : !question ? (
          <Option toggleStateDropdown={toggleStateDropdown} />
        ) : (
          <>
            <Question question={question} />
            <Option toggleStateDropdown={toggleStateDropdown} />
          </>
        )}
      </div>
      {subtype === "otp" && !isOtpSubmitted && !isLoading ? 
        <Button toggleStateDropdown={toggleStateDropdown} msg={"Didn’t recieved OTP ?"} buttonText={timer ? `Resend in ${timer}s`: "Resend"} buttonClassName={ timer ? 'text-colorGrey': ''} handleButtonClick={handleButtonClick}/>
        :
        null
      }
      {/* <ChatLoader /> */}
    </ConversationContext.Provider>
  );
};
