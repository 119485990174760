import React from "react";
import infoIcon from "../assets/Images/info.svg";

export const Button = ({ msg, buttonText, buttonClassName, handleButtonClick = () => {}}) => {
  return (
    <>
     {msg ? <div className="text-left grid infoIconContiner text-sm w-max font-bold text-colorSimpleMessageText bg-colorSimpleMessage border-colorSecondary border-2 mt-3 p-2 rounded shadow-md">
        <img src={infoIcon} alt="infoIcon" className="infoIcon"></img>
        <p> {msg} &nbsp;&nbsp;
          {buttonText ? <span className={`${buttonClassName ? buttonClassName : 'underline text-colorSecondary cursor-pointer'}`} onClick={handleButtonClick}>{buttonText}</span>: null}
          </p>
      </div>
      :
      null  
    }
    </>
  );
};
