import React from "react";
import infoIcon from "../assets/Images/info.svg";

export const Simple = ({ msg }) => {
  return (
    <>
     {msg ? <div className="text-left grid infoIconContiner text-sm w-full font-bold text-colorSimpleMessageText bg-colorSimpleMessage border-colorSecondary border-2 mt-3 p-2 rounded shadow-md">
        <img src={infoIcon} alt="infoIcon" className="infoIcon"></img>
        <p> {msg}</p>
      </div>
      :
      null  
    }
    </>
  );
};
